import styles from "./TwoColumnTemplate.module.scss";
import cx from "classnames";

export const TwoColumnTemplate: React.FC<{ className?: string }> = ({
  children,
  className,
}) => <div className={cx(styles.root, className)}>{children}</div>;

export const RightSide: React.FC<{ className?: string }> = ({
  children,
  className,
}) => <div className={cx(styles.right, className)}>{children}</div>;

export const Main: React.FC<{ className?: string }> = ({
  children,
  className,
}) => <div className={cx(styles.main, className)}>{children}</div>;
