import type { NextPage } from "next";
import { useRouter } from "next/router";
import styles from "./index.module.scss";
import {
  TripCard,
  TaskCard,
  NoTasks,
  SuperliteDashboard,
  SocialImages,
} from "@/components/Dashboard";
import {
  TwoColumnTemplate,
  RightSide,
  Main,
} from "@/components/TwoColumnTemplate";
import { useTasksQuery } from "@/fetch/progress";
import { ChevronIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import Grid from "@mui/material/Grid";
import { useDialog, useLock, useResponsive, useTrackers } from "@/hooks";
import { TopLevelDialogNames } from "@/components/TopLevelDialogs";
import Button from "@/components/Button";
import { AvatarGroup } from "@/components/Avatar";
import { Skeleton } from "@/components/Loader";
import { GroupMembers } from "@/components/Social";
import Divider from "@/components/Divider";
import { RecentMedia } from "@/components/Social";
import { useGroupQuery } from "@/fetch/social";
import { SecondStepper } from "@/components/Stepper";
import { useEffect, useState } from "react";

const DashboardPage: NextPage = ({}) => {
  const { openTopLevelDialog } = useDialog();
  const { isLoading: isLockLoading, isSuperliteTrip } = useLock();
  const { data, isLoading: isTasksLoading } = useTasksQuery();
  const avatarPhotoUrls = data?.staff.map((s) => s.photo_url) || [];
  const tasks = data?.tasks || [];
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const isLoading = isTasksLoading || isFirstLoading || isLockLoading;

  const { push } = useRouter();
  const { isMobile } = useResponsive();
  const { track } = useTrackers();

  const {
    isLoading: isGroupLoading,
    refetch: refetchGroup,
    isError,
  } = useGroupQuery({
    enabled: false,
    retry: false,
  });

  useEffect(() => {
    setIsFirstLoading(false);
  }, []);

  const goToSocial = () => {
    track("SuperLite Connect Social Clicked", {
      eventId: "superlite-connect-social-clicked",
    });
    push("/social/timeline?tab=feed");
  };

  const hasSocialGroup = !isGroupLoading && !isError;

  const superLiteSteps = [
    { title: "Visa application", path: "/experience/visas" },
    { title: "Travel guides", path: "/experience/resources?tab=resources" },
    { title: "Create resume", path: "/experience/resume" },
    { title: "Watch & learn", path: "/academy" },
    { title: "Get insured", path: "/experience/insurance" },
    { title: "Make friends", path: "/social" },
    { title: "Find deals", path: "/marketplace" },
  ];

  return (
    <TwoColumnTemplate>
      <Main className={styles.container}>
        <TripCard />
        {isMobile && isSuperliteTrip && (
          <>
            <Typography
              variant="h6"
              color="text.secondary"
              className={styles.mobileStepperTitle}
            >
              Follow These Steps
            </Typography>
            <div className={styles.mobileStepperContainer}>
              <SecondStepper steps={superLiteSteps} direction="horizontal" />
            </div>
          </>
        )}
        {isSuperliteTrip ? (
          <SuperliteDashboard />
        ) : (
          <>
            {isLoading && (
              <>
                <Typography variant="h6" className={styles.title}>
                  <Skeleton width="200px" />
                </Typography>
                <Grid container spacing={2.5} className={styles.cards}>
                  {[1, 2].map((_, index) => (
                    <Grid item key={index} xs={12} md={6}>
                      <Skeleton variant="rectangular" height="100px" />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            {tasks.length === 0 && !isLoading && <NoTasks />}
            {Boolean(tasks.length) && (
              <>
                <Typography variant="h6" className={styles.title}>
                  Tasks you should complete...
                </Typography>
                <Grid container spacing={2.5} className={styles.cards}>
                  {tasks.map((task, index) => (
                    <Grid item key={index} xs={12} md={6}>
                      <TaskCard task={task} />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </>
        )}
      </Main>
      <RightSide>
        {isSuperliteTrip ? (
          <>
            {hasSocialGroup && (
              <>
                <Typography variant="subtitle1" color="text.secondary">
                  Keep in touch with your travel pals
                </Typography>
                <GroupMembers hideDescription hideMemberCounts />
                <Typography
                  variant="body1"
                  onClick={goToSocial}
                  className={styles.connectText}
                >
                  Connect now
                  <ChevronIcon className={styles.ChevronIcon} />
                </Typography>
              </>
            )}

            {hasSocialGroup && <Divider className={styles.rightSideDivider} />}

            <RecentMedia variant="SuperliteDashboard" />
            <Divider className={styles.divider} />
            <div className={styles.stepperContainer}>
              <Typography
                color="text.secondary"
                variant="subtitle1"
                className={styles.stepperTitle}
              >
                Follow These Steps
              </Typography>
              <SecondStepper steps={superLiteSteps} />
            </div>
          </>
        ) : (
          <>
            {avatarPhotoUrls.length > 0 && (
              <div
                onClick={() => openTopLevelDialog(TopLevelDialogNames.Experts)}
                className={styles.experts}
              >
                <Typography variant="subtitle1" className={styles.text}>
                  Your Travel Experts
                </Typography>
                <AvatarGroup
                  imageUrls={avatarPhotoUrls}
                  className={styles.avatars}
                />
                <Button
                  variant="text"
                  color="inherit"
                  endIcon={<ChevronIcon />}
                >
                  Have a question?
                </Button>
              </div>
            )}
          </>
        )}
      </RightSide>
    </TwoColumnTemplate>
  );
};

export default DashboardPage;
